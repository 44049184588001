import React from 'react';
import './interface.css';
import ThumbWorld from './Thumbworld';

const Sidebar = (props) => {

  const handleClick = (worldString) => {
  //  const message = `Hello from ${uniqueString}!`; // Customize the message based on the unique string
    props.sendMessage(worldString); // Sending the message to the parent component
  };

  return (
    <div className="sideBar">
    <div className="dragText">Drag &amp; Explore</div>
      <ThumbWorld onClick={() => handleClick("world1.fbx")} c2={"t1"}/>
      <ThumbWorld onClick={() => handleClick("world2.fbx")} c2={"t2"}/>
      <ThumbWorld onClick={() => handleClick("world3.fbx")} c2={"t3"}/>
      <ThumbWorld onClick={() => handleClick("world4.fbx")} c2={"t4"}/>
      <ThumbWorld onClick={() => handleClick("world5.fbx")} c2={"t5"}/>
      <ThumbWorld onClick={() => handleClick("world6.fbx")} c2={"t6"}/>
      <ThumbWorld onClick={() => handleClick("world7.fbx")} c2={"t7"}/>
      <ThumbWorld onClick={() => handleClick("world8.fbx")} c2={"t8"}/>
      <ThumbWorld onClick={() => handleClick("world9.fbx?1")} c2={"t9"}/>
    </div>
  );
};

export default Sidebar;
