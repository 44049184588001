import React, { useEffect, useState, useRef } from 'react';
import * as THREE from 'three';
import { OrbitControls } from './OrbitControls';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';




const Scene = (props) => {

  const renderer = new THREE.WebGLRenderer({ antialias: true })
  renderer.setSize(window.innerWidth, window.innerHeight);
  renderer.setPixelRatio(window.devicePixelRatio);
  const worldObject = props.world;
  const scene = new THREE.Scene();
  const loader = new FBXLoader();

  const [readyState, setReadyState] = useState(false);
  const sceneRef = useRef(null);
  const autoRSceneRef = useRef(true);
  const raycaster = new THREE.Raycaster();
  const mouse = new THREE.Vector2();


  function loadScene(){
    loader.load('/scenes/' + worldObject, (fbx) => {



      scene.clear(); // Clear the scene before adding the new FBX file
      //console.log(scene,worldObject)
      emissive(fbx)
      scene.add(fbx);

    });
  }

  function emissive(fbx) {
    fbx.traverse((child) => {
      if (child.isMesh) {
        // Update the material's emissiveIntensity
        child.material.emissiveIntensity = 6;
        child.material.side = THREE.DoubleSide;
        child.material.transparent = true;
        child.material.alphaTest = 0.1;
      }
    });
  }


  useEffect(() => {
    // Set up the camera
    const camera = new THREE.PerspectiveCamera(
      75,
      window.innerWidth / window.innerHeight,
      0.1,
      1000
    );
    camera.position.z = 5;


    //console.log("AA")

    // Set up the renderer


    while (sceneRef.current.firstChild) {
      sceneRef.current.removeChild(sceneRef.current.firstChild);
    }

    // Append new renderer's canvas element
    sceneRef.current.appendChild(renderer.domElement);
   //loadScene("world2.fbx")

    // Add orbital controls with damping
    const controls = new OrbitControls(camera, renderer.domElement);
    controls.enableDamping = true;
    controls.dampingFactor = 0.05;
    controls.enableZoom = false;
    controls.autoRotate = true;
    controls.autoRotateSpeed = 0.5; // Adjust the auto rotation speed here

    // Load the FBX file
    loadScene()


    const handleMouseMove = (event) => {
      // Calculate normalized device coordinates (-1 to +1) for the mouse
      mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
      mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
    };

    // Add event listener for mouse movement
    window.addEventListener('mousemove', handleMouseMove, false);

    const handleUserInteraction = () => {
      autoRSceneRef.current = true; //make false to turn off auto drag
      // Check if the mouse is over any object in the scene
      raycaster.setFromCamera(mouse, camera);
      const intersects = raycaster.intersectObjects(scene.children, true);

      if (intersects.length > 0) {
        const clickedObject = intersects[0].object;

        if (clickedObject.name.startsWith('target')) {
          console.log('Clicked Mesh:', clickedObject.name);
        }
      }
    };

    // Add click event listener to the scene
    window.addEventListener('click', handleUserInteraction);

    // Animation loop
    const animate = () => {
      requestAnimationFrame(animate);

      if (autoRSceneRef.current) {
        controls.autoRotate = true;
      } else {
        controls.autoRotate = false;
      }

      controls.update(); // Update the controls in the animation loop
      renderer.render(scene, camera);
    };
    animate();

    // Update the size of the canvas when the window is resized
    const handleResize = () => {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(window.innerWidth, window.innerHeight);
    };
    window.addEventListener('resize', handleResize);

    setReadyState(true);

    return () => {
      // Clean up event listeners and memory on unmount
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('click', handleUserInteraction);
      window.removeEventListener('resize', handleResize);
      controls.dispose();
      renderer.dispose();
    };
  }, [worldObject]);

  useEffect(() => {

    if (readyState) {
           //loadScene()

    }
  }, [readyState]);

  return <div ref={sceneRef} style={{ width: '100vw', height: '100vh' }} />;
};

export default Scene;
