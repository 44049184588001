import React from 'react';
import './interface.css';

const ThumbWorld = (props) => {

  const handleClick = () => {
    // Handle the click event
    props.onClick();
  };

  return (
    <div className={`thumbWorldHolder ${props.c2}`} onClick={handleClick}>
      {/* Content of the ThumbWorld component */}
    </div>
  );
};

export default ThumbWorld;
