import React, { useState } from 'react';
import Sidebar from './Sidebar';
import Scene from './Scene';
import './interface.css';

const App = () => {
  const [worldObject, setWorldObject] = useState("world1.fbx");

  const handleSidebarMessage = (message) => {
    // Handle the received message from the Sidebar component
    //console.log(message);
    setWorldObject(message); // Update the value of worldObject
  };

  return (
    <div>
      <Sidebar sendMessage={handleSidebarMessage} />
      <Scene world={worldObject} />
    </div>
  );
};

export default App;
